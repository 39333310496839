import { TokenStatic, TokenFactoryStatic } from '@injectivelabs/sdk-ts'
import { LocalStorage } from '@injectivelabs/utils'
import { env, NETWORK } from '@shared/utils/constant'
import { tokens } from '@/app/json'

export const tokenFactoryStatic = new TokenFactoryStatic(
  tokens as TokenStatic[]
)

// Singletons
export const localStorage: LocalStorage = new LocalStorage(
  `inj-hub-network-v3-${NETWORK}-${env.VITE_ENV || 'mainnet'}`
)
