import { LocalStorage } from '@injectivelabs/utils'
import { env, NETWORK } from '@shared/utils/constant'
import { localStorage } from '@/app/Service'

// Clear up old local storage keys
export default defineNuxtPlugin(() => {
  const MAX_LIMIT_OLD = 15
  const MAX_LIMIT = 1

  if (localStorage.has('storageCleaned')) {
    return
  }

  for (let i = 0; i <= MAX_LIMIT_OLD; i++) {
    const localStorageByIndex = new LocalStorage(
      `inj-hub-v14-${NETWORK}-${env.VITE_ENV || 'mainnet'}`
    )

    localStorageByIndex.clear()
  }

  for (let i = 0; i < MAX_LIMIT; i++) {
    const localStorageByIndex = new LocalStorage(
      `inj-hub-network-v${i}-${NETWORK}`
    )

    localStorageByIndex.clear()
  }

  localStorage.set('storageCleaned', 'true')
})
